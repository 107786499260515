export default [
  {
    path: "/control-rrp",
    component: () => import("@/pages/ControlRRP/Index.vue"),
    name: "ControlRRPIndex",
    meta: {
      title: 'Контроль РРЦ',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'], //'IN_WB', 'IN_OZ'
    },
    redirect: { name: 'ControlRRPTable' },
    children: [
      {
        path: "",
        component: () => import("@/pages/ControlRRP/Table.vue"),
        name: "ControlRRPTable",
      },
    ],
  }
]
