import { createRouter as _createRouter, createWebHistory } from 'vue-router'

import PriceMonitoring from "./routes/PriceMonitoring.router.js"
import Report from "./routes/Reports.router.js"
import ProductAnalytics from "./routes/ProductAnalytics.router.js"
import ControlRRP from "./routes/ControlRRP.router.js"

const routes = [
  {
    path: "/",
    component: () => import("@/pages/Dashboard/Index.vue"),
    name: "Dashboard",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      // checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
    },
    children: [
      {
        path: "dashboard/basic",
        component: () => import('@/pages/Dashboard/MainFactors.vue'),
        name: "DashboardMainFactors",
        meta: {
          checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
        },
      },
      {
        path: "dashboard/:customerId",
        component: () => import('@/pages/Dashboard/Organization.vue'),
        name: "DashboardOrganization",
      },
    ]
  },
  /*
   * Внутреняя аналитика
   */
  /**
   * Поиск
   */
  {
    path: "/search",
    component: () => import("@/pages/RouterView.vue"),
    name: 'Search',
    redirect: {name: "SearchWithSKU"},
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
    },
    children: [
      {
        path: "sku",
        component: () => import("@/pages/Search/Index.vue"),
        name: "SearchWithSKUIndex",
        redirect: { name: 'SearchWithSKURubSales' },
        children: [
          {
            path: "",
            name: "SearchWithSKURubSales",
            component: () => import("@/pages/Search/SKU.vue"),
          },
          {
            path: "sales",
            name: "SearchWithSKUSales",
            component: () => import("@/pages/Search/SKUSales.vue"),
          },
          {
            path: "favorites",
            name: "SearchFavoritesWithSKU",
            component: () => import("@/pages/Search/SKUFavorites.vue"),
          }
        ],
      },
      {
        path: "brand",
        component: () => import("@/pages/Search/Index.vue"),
        name: "SearchWithBrandIndex",
        redirect: { name: 'SearchWithBrand' },
        children: [
          {
            path: "",
            name: "SearchWithBrand",
            component: () => import("@/pages/Search/Brand.vue"),
          },
          {
            path: "favorites",
            name: "SearchFavoritesWithBrand",
            component: () => import("@/pages/Search/BrandFavorites.vue"),
          }
        ],
      },
      {
        path: "seller",
        component: () => import("@/pages/Search/Index.vue"),
        name: "SearchWithSellerIndex",
        redirect: { name: 'SearchWithSeller' },
        children: [
          {
            path: "",
            name: "SearchWithSeller",
            component: () => import("@/pages/Search/Seller.vue"),
          },
          {
            path: "favorites",
            name: "SearchFavoritesWithSeller",
            component: () => import("@/pages/Search/SellerFavorites.vue"),
          }
        ],
      },
      {
        path: "categories",
        component: () => import("@/pages/Search/Index.vue"),
        name: "SearchWithCategoriesIndex",
        redirect: { name: 'SearchWithCategories' },
        children: [
          {
            path: "",
            name: "SearchWithCategories",
            component: () => import("@/pages/Search/Categories.vue"),
          },
          {
            path: "tree",
            name: "SearchTreeWithCategories",
            component: () => import("@/pages/Search/CategoriesTree.vue"),
          },
          {
            path: "favorites",
            name: "SearchFavoritesWithCategories",
            component: () => import("@/pages/Search/CategoriesFavorites.vue"),
          }
        ],
      },
    ],
  },
  /**
   * Таблицы
   */
  {
    path: "/:mp?/brand/:brand_id",
    component: () => import("@/pages/Tables/Brand/Index.vue"),
    name: "BrandIndex",
    redirect: {name: "BrandTableGoods"},
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
    },
    children: [
      {
        path: "goods",
        component: () => import("@/pages/Tables/Brand/Goods.vue"),
        name: "BrandTableGoods",
      },
      {
        path: "categories",
        component: () => import("@/pages/Systems/UnavailableInProgressDevelop.vue"),
        name: "BrandTableCategories",
      },
      {
        path: "sellers",
        component: () => import("@/pages/Tables/Brand/Sellers.vue"),
        name: "BrandTableSellers",
      },
      {
        path: "trend",
        component: () => import("@/pages/Systems/UnavailableInProgressDevelop.vue"),
        name: "BrandTableTrend",
      },
      {
        path: "by-days",
        component: () => import("@/pages/Systems/UnavailableInProgressDevelop.vue"),
        name: "BrandTableByDays",
      },
    ]
  },
  {
    path: "/:mp?/seller/:seller_id",
    component: () => import("@/pages/Tables/Seller/Index.vue"),
    name: "SellerIndex",
    redirect: {name: "SellerTableGoods"},
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
    },
    children: [
      {
        path: "goods",
        component: () => import("@/pages/Tables/Seller/Goods.vue"),
        name: "SellerTableGoods",
      },
      {
        path: "categories",
        component: () => import("@/pages/Systems/UnavailableInProgressDevelop.vue"),
        name: "SellerTableCategories",
      },
      {
        path: "brands",
        component: () => import("@/pages/Tables/Seller/Brands.vue"),
        name: "SellerTableBrands",
      },
      {
        path: "trend",
        component: () => import("@/pages/Systems/UnavailableInProgressDevelop.vue"),
        name: "SellerTableTrend",
      },
      {
        path: "by-days",
        component: () => import("@/pages/Systems/UnavailableInProgressDevelop.vue"),
        name: "SellerTableByDays",
      },
    ]
  },
  {
    path: "/:mp?/category/:category_int_id",
    component: () => import("@/pages/Tables/Category/Index.vue"),
    name: "CategoryIndex",
    redirect: {name: "CategoryTableGoods"},
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
    },
    children: [
      {
        path: "goods",
        component: () => import("@/pages/Tables/Category/Goods.vue"),
        name: "CategoryTableGoods",
      },
      // {
      //   path: "sellers",
      //   component: () => import("@/pages/Tables/Category/Sellers.vue"),
      //   name: "CategoryTableSellers",
      // },
      // {
      //   path: "brands",
      //   component: () => import("@/pages/Tables/Category/Brands.vue"),
      //   name: "CategoryTableBrands",
      // },
    ]
  },
  /**
   * Карточка товара
   */
  {
    path: "/:mp?/item/:sku",
    component: () => import("@/pages/Product/Index.vue"),
    name: "ProductDetail",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'],
    },
    redirect: {
      name: "ProductSalesAndBalances",
    },
    children: [
      // {
      //   path: "info",
      //   component: () => import("@/pages/Product/Info/Index.vue"),
      //   name: "ProductDetail",
      //   meta: {title: 'Карточка товара' },
      // },
      {
        path: "sales-and-balances",
        component: () => import("@/pages/Product/SalesAndBalances/Index.vue"),
        name: "ProductSalesAndBalances",
        meta: {title: 'Продажи и остатки' },
      },
      {
        path: "remaining-stocks-and-sizes",
        component: () => import("@/pages/Product/RemainingStocksAndSizes/Index.vue"),
        name: "ProductRemainingStocksAndSizes",
        meta: {title: 'Остатки по складам и размерам' },
      },
      {
        path: "categories",
        component: () => import("@/pages/Product/Categories/Index.vue"),
        name: "ProductCategories",
        meta: {title: 'Категории' },
      },
    ]
  },

  /*
   * Внешнняя аналитика
   */
  ...Report,
  ...ProductAnalytics,
  ...(+import.meta.env.VITE_APP_RRP_CONTROL ? ControlRRP : []),


  /*
   * Клиенты (Организации)
   */
  {
    path: "/clients",
    component: () => import("@/pages/Clients/Index.vue"),
    name: "Clients",
    redirect: {name: "ClientsList"},
    meta: {
      title: 'Организации',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=ext`,
    },
    children: [
      {
        path: "",
        component: () => import("@/pages/Clients/ItemList/Index.vue"),
        name: "ClientsList",
        meta: {
          // title: 'Список организаций',
        },
        children: [
          {
            path: "",
            component: () => import("@/pages/Clients/ItemList/Table.vue"),
            name: "ClientsListTable"
          },
        ],
      },
      // Информация об организации
      {
        path: ":clientId",
        component: () => import("@/pages/Clients/CommonInfo/Index.vue"),
        name: "ClientsDetail",
        redirect: {name: "ClientsDetailsPayment"},
        meta: {
          title: "Информация об организации",
          checkAvailableWithTariff: ['GR_LK'],
        },
        children: [
          // Оплата тарифа
          {
            path: "payment",
            component: () => import("@/pages/Clients/CommonInfo/Payment/Index.vue"),
            name: "ClientsDetailsPayment",
            meta: { title: "Финансы" },
          },
          // Тарифные планы
          {
            path: "payment/tariffs",
            component: () => import("@/pages/Clients/CommonInfo/Payment/Tariffs.vue"),
            name: "ClientsDetailsPaymentTariffs",
            meta: { title: "Тарифные планы" },
          },
          // Управление ключами МП
          {
            path: "marketplaces",
            component: () => import("@/pages/Clients/CommonInfo/Marketplaces/Index.vue"),
            name: "ClientsDetailsMarketplace",
            redirect: {name: "ClientsDetailsMarketplaceList"},
            meta: {title: "Маркетплейсы"},
            children: [
              {
                path: "",
                name: "ClientsDetailsMarketplaceList",
                component: () => import("@/pages/Clients/CommonInfo/Marketplaces/ItemsList.vue"),
              },
            ],
          },
          // Список пользователей
          {
            path: "members",
            component: () => import("@/pages/Clients/CommonInfo/Members/Index.vue"),
            name: "ClientsDetailMembers",
            redirect: {name: "ClientsDetailsMembersList"},
            meta: {title: "Пользователи"},
            children: [
              {
                path: "",
                name: "ClientsDetailsMembersList",
                component: () => import("@/pages/Clients/CommonInfo/Members/ItemsList.vue"),
              },
              // Информация об пользователе
              {
                path: ":memberId",
                component: () => import("@/pages/Clients/CommonInfo/Members/Info.vue"),
                name: "ClientsDetailMembersInfo",
              },
            ],
          },
          // Управление рассылкой отчетов
          // {
          //   path: "mailing",
          //   component: () => import("@/pages/Clients/CommonInfo/Mailing/Index.vue"),
          //   name: "ClientsDetailsMailing",
          //   meta: { title: "Рассылка отчетов" },
          // }
        ],
      },
    ],
  },

  /**
   * Общие руты
   */
  {
    path: "/account",
    component: () => import("@/pages/Profile/Index.vue"),
    name: "ProfileView",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
  },
  {
    path: "/sign-in",
    component: () => import("@/pages/Auth/SignIn.vue"),
    name: "SignIn",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
  },
  {
    path: "/sign-up",
    component: () => import("@/pages/Auth/SignUp.vue"),
    name: "SignUp",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
  },
  {
    path: "/suggest",
    component: () => import("@/pages/RouterView.vue"),
    name: "SuggestToken",
    redirect: { path: '/' },
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
    children: [
      {
        path: "yandex",
        component: () => import("@/pages/Auth/integrations/YandexToken.vue"),
      },
      {
        path: "google",
        component: () => import("@/pages/Auth/integrations/GoogleToken.vue"),
      },
    ],
  },
  {
    path: "/confirm-registration",
    component: () => import("@/pages/Auth/ConfirmRegistration.vue"),
    name: "ConfirmRegistration",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
  },
  {
    path: "/change-password",
    component: () => import("@/pages/Auth/ChangePassword.vue"),
    name: "ChangePassword",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
  },
  {
    path: "/recovery",
    component: () => import("@/pages/Auth/Recovery.vue"),
    name: "RecoveryPassword",
    meta: {
      checkAvailableService: `${import.meta.env.VITE_APP_APFSLK_URL}/ping`,
    },
  },

  {
    path: "/confirm",
    component: () => import("@/pages/Confirm/Index.vue"),
    name: "ConfirmIndex",
    redirect: {path: "/"},
    children: [
      {
        path: 'add-user',
        component: () => import("@/pages/Confirm/AddUser.vue"),
        name: "ConfirmAddUser",
      },
    ],
  },

  {
    path: "/import-data",
    component: () => import("@/pages/ImportData.vue"),
    name: "ImportData",
  },

  /**
   * Other systems page
   */
  {
    path: "/unavailable",
    component: () => import("@/pages/Systems/UnavailableWithPing.vue"),
    name: "UnavailableWithPing",
  },
  {
    path: "/maintenance",
    component: () => import("@/pages/Systems/UnavailableWithMaintenance.vue"),
    name: "UnavailableWithMaintenance",
  },
  {
    path: "/paid-content",
    component: () => import("@/pages/Systems/UnavailableWithTariff.vue"),
    name: "UnavailableWithTariff",
  },

  {
    path: "/unsubscribe/:uuid?",
    component: () => import("@/pages/Systems/Unsubscribe.vue"),
    name: "Unsubscribe",
  },
  {
    path: "/i",
    component: () => import("@/pages/Systems/Registration/Index.vue"),
    name: "RegistrationIndex",
    redirect: { name: "RegistrationCounts" },
    children: [
      {
        path: "",
        component: () => import("@/pages/Systems/Registration/Users.vue"),
        name: "RegistrationCounts",
      },
      {
        path: "organization",
        component: () => import("@/pages/Systems/Registration/Organization.vue"),
        name: "RegistrationOrganization"
      },
      {
        path: ":userId/detail/:fio/:login",
        component: () => import("@/pages/RouterView.vue"),
        redirect: {name: "RegistrationLogDetail"},
        children: [
          {
            path: "",
            component: () => import("@/pages/Systems/Registration/UsersLogDetail.vue"),
            name: "RegistrationLogDetail",
          },
        ]
      },
    ],
  },
  // for web extensions
  {
    path: "/plugin-comeback",
    component: () => import("@/pages/Systems/WebExtensions/Comeback.vue"),
    name: "PluginComeback",
  },
  {
    path: "/plugin-install",
    component: () => import("@/pages/Systems/WebExtensions/Install.vue"),
    name: "PluginInstall",
  },

  {
    path: '/:pathMatch(.*)*',
    component: () => import("@/pages/Systems/404.vue"),
    name: 'NotFound',
    props: true,
  },


  ...PriceMonitoring,
];

export function createRouter () {
  const router = _createRouter({
    history: createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes,
  })

  return router;
}
